<template>
  <div class="maxHeight">
    <div class="header">
      <div class="title flex">
        <img src="../../assets/img/user_b.png" alt="" width="28">
        <h2>访客管理</h2>
        <span class="num">共计：{{ page.total }}条记录</span>
      </div>
    </div>
    <div class="contentBody">
      <div class="flex tableHead">
        <el-button size="small" @click="add">新增访客</el-button>
        <el-input clearable v-model="search" placeholder="请输入关键信息" suffix-icon="el-icon-search"></el-input>
      </div>
      <el-table
          border
          :data="tableData"
          class="record-table"
          :header-cell-style="{'background':'rgba(213, 234, 255, 0.5)','color': '#006DFF','fontWeight':'500'}"
          height="calc(100vh - 340px)">
        <el-table-column prop="nickname" label="姓名" width="150" show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="用户名" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.username.split('.')[1] }}
          </template>
        </el-table-column>
        <el-table-column prop="mobile" label="手机" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="remark" label="公司名称" show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="推荐人" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.visitorPerm ? scope.row.visitorPerm.createNickname : '-' }}
          </template>
        </el-table-column>
        <el-table-column label="操作权限">
          <template slot-scope="scope">
            <el-switch
                disabled
                @click.native="changeVistor(scope.row)"
                v-model="scope.row.status"
                :active-value="'1'" :inactive-value="'0'"
                active-color="#4F9AFE"
                inactive-color="#BBBBBB">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" size="small">复制</el-button>
            <el-button type="text" size="small" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
            <el-button type="text" size="small" @click="handleDelete(scope.row.userId)">删除</el-button>
            <el-button type="text" size="small" @click="reset(scope.row)">重置密码</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="flex pagination ">
        <el-pagination
            prev-text="<"
            next-text=">"
            @size-change="handleSizeChange"
            @current-change="skipPage"
            :current-page="page.current"
            :page-size="page.size"
            layout="prev, pager, next"
            :total="page.total">
        </el-pagination>
        <span class="total">共计{{ Math.ceil(page.total / page.size) }}页</span>
      </div>
    </div>
    <!--新增编辑访客-->
    <el-dialog :show-close="false" :close-on-click-modal="false"
               :title="action+'访客'"
               class="flex dialogForm "
               top="0"
               :visible.sync="dialogFormVisible"
               width="400px">
      <el-form ref="formAction" size="small" :model="formAction" class="addForm" label-suffix=":"
               label-width="80px">
        <el-form-item label="姓名" prop="name">
          <el-input v-model="formAction.nickname" autocomplete="off" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="用户名" prop="preUsername">
          <el-input placeholder="请输入用户名" v-model="formAction.preUsername" autocomplete="off">
          </el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password" :style="{'display':baseStyles}">
          <el-input v-model="formAction.password" type="password" autocomplete="off" placeholder="请输入密码"></el-input>
        </el-form-item>
        <el-form-item label="手机号码" prop="mobile">
          <el-input placeholder="请输入手机号码" v-model="formAction.mobile" autocomplete="off">
          </el-input>
        </el-form-item>
        <el-form-item label="公司名称" prop="remark">
          <el-input placeholder="请输入公司名称" v-model="formAction.remark" autocomplete="off">
          </el-input>
        </el-form-item>
        <!--        <el-form-item label="权限内容">
                  <el-checkbox-group v-model="formAction.permissions">
                    <el-checkbox label="查看笼舍"></el-checkbox>
                    <el-checkbox label="查看课题"></el-checkbox>
                  </el-checkbox-group>
                </el-form-item>-->
        <el-form-item label="查看笼舍">
          <el-select
              multiple value-key="id"
              class="smallWidth"  collapse-tags
              v-model="formAction.cageList"
              filterable
              default-first-option
              v-el-select-loadmore="loadAllCage"
              placeholder="请选择笼舍">
            <el-option
                v-for="item in cageList"
                :key="item.id"
                :label="item.number"
                :value="item.id"></el-option>
          </el-select>
          <!--          <el-select v-model="formAction.cageIds" multiple placeholder="请选择笼舍">
                      <el-option
                          v-for="item in cageList"
                          :key="item.id"
                          :label="item.number"
                          :value="item.id">
                      </el-option>
                    </el-select>-->
          <!--          <div class="modeFor" v-for="(obj,ind) in cage">
                      <el-input :key="ind" v-model="obj.number" clearable
                                :placeholder='"请输入笼舍编号"+(ind+1)'
                                class="smallWidth"></el-input>
                      <el-button size="small" plain class="delIns" icon="el-icon-close" @click="delIns(ind,0)"></el-button>
                    </div>
                    <el-button icon="el-icon-plus" class="addInstru" @click="addExper(0)">添加笼舍编号</el-button>-->
        </el-form-item>
        <el-form-item label="课题编号">
          <el-select
              class="smallWidth"  collapse-tags
              v-model="formAction.subjectList"
              filterable value-key="id"
              multiple
              default-first-option
              v-el-select-loadmore="loadAll"
              placeholder="请选择课题编号">
            <el-option
                v-for="item in subjectList"
                :label="item.number"
                :value="item.id"
                :key="item.id"></el-option>
          </el-select>
          <!--          <div class="modeFor" v-for="(obj,ind) in issue">
                      <el-input :key="ind" v-model="obj.number" clearable
                                :placeholder='"请输入课题编号"+(ind+1)'
                                class="smallWidth"></el-input>
                      <el-button size="small" plain class="delIns" icon="el-icon-close" @click="delIns(ind,1)"></el-button>
                    </div>
                    <el-button icon="el-icon-plus" class="addInstru" @click="addExper(1)">添加课题</el-button>-->
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="commit('formAction')">确认</el-button>
          <el-button size="small" type="primary" plain @click="dialogFormVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!--重置密码-->
    <el-dialog :close-on-click-modal="false"
               class="commonDialog flex" center
               top="0"
               :visible.sync="resetPwd"
               width="260px">
      <img src="../../assets/img/tips.png" alt="" width="52px">
      <h3>是否确认将该访客的密码重置为：{{ newPwd.password }}</h3>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="submit">确认</el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>
import {mapState} from "vuex";

export default {
  name: "visitors",
  directives: {
    'el-select-loadmore': {
      bind(el, binding) {
        const SELECTWRAP_DOM = el.querySelector(
            '.el-select-dropdown .el-select-dropdown__wrap'
        );
        SELECTWRAP_DOM.addEventListener('scroll', function () {
          const condition =
              this.scrollHeight - this.scrollTop <= this.clientHeight;
          if (condition) {
            binding.value();
          }
        });
      }
    }
  },
  data() {
    return {
      search: '',
      tableData: [],
      page: {
        current: 1,
        size: 10,
        total: 10,
      },
      dialogFormVisible: false,
      action: '新增',
      formAction: {
        nickname: '',
        preUsername: '',
        password: '',
        mobile: '',
        remark: '',
        permissions: [],
        subIds: "",
        cageIds: "",
        subjectList: [],
        cageList: []
      },
      issue: [],
      cage: [],
      resetPwd: false,
      baseStyles: 'none',
      newPwd: {
        username: "",
        password: "123456"
      },
      subjectList: [],
      formData: {   //下拉参数
        pageIndex: 1,
        pageSize: 10
      },
      cageList: [],
      formDataCage: {   //下拉参数
        pageIndex: 1,
        pageSize: 10
      },
    }
  },
  computed: {
    ...mapState({
      user: state => state.account.user
    }),
  },
  mounted() {
    this.getDataFromServer()
    this.getSupply();
    this.getCage()
  },
  methods: {
    getDataFromServer() {
      this.$get('/oauth/user/visitor/page/' + this.user.companyId,
          {
            pageSize: this.page.size,
            pageNum: this.page.current,
            key: this.search
          }).then(res => {
        this.tableData = res.data.records
        this.page.current = res.data.current;
        this.page.total = res.data.total;
        this.page.size = res.data.size;
      })
    },
    // 每页多少条
    handleSizeChange(val) {
      this.page.size = val;
    },
    // 当前页
    skipPage(current) {
      this.page.current = current;
      this.getDataFromServer();
    },
    // 获取课题
    loadAll() {
      this.formData.pageIndex++;
      this.getSupply(this.formData);
    },
    getSupply() {
      let that = this
      let param = {
        pageSize: that.formData.pageSize,
        pageNum: that.formData.pageIndex,
      };
      this.$get("/subject/byPage", param).then(res => {
        this.subjectList = [...this.subjectList, ...res.data.records]
      }).catch(() => {
        this.$message.error('查询失败');
      })
    },
    loadAllCage() {
      this.formDataCage.pageIndex++;
      this.getCage(this.formDataCage);
    },
    getCage() {
      let that = this
      let param = {
        pageSize: that.formDataCage.pageSize,
        pageNum: that.formDataCage.pageIndex,
      };
      this.$get("/subject/cage/visitor/" + this.user.companyId, param).then(res => {
        this.cageList = [...this.cageList, ...res.data.records]
      }).catch(() => {
        this.$message.error('查询失败1');
      })
    },
    /*// 添加课题，笼舍
    addExper(ind) {
      if (ind == 0) {
        this.cage.push({
          number: ""
        })
      } else {
        this.issue.push({
          number: ""
        })
      }
    },
    // 删除课题，笼舍
    delIns(index, ind) {
      if (ind == 0) {
        this.cage.splice(index, 1)
      } else {
        this.issue.splice(index, 1)
      }
    },*/
    // 新增
    add() {
      this.baseStyles = '';
      this.isEdit = false;
      this.action = '新增'
      this.formAction = {}
      this.dialogFormVisible = !this.dialogFormVisible;
    },
    //   编辑
    handleEdit(index, row) {
      console.log(row)
      this.isEdit = true;
      this.action = '编辑'
      this.formAction = {
        userId: row.userId,
        nickname: row.nickname,
        preUsername: row.username.length > 0 ? row.username.split('.')[1] : '',
        mobile: row.mobile,
        remark: row.remark,
        cageList:row.cageList,
        subjectList: row.subList
      }
      this.dialogFormVisible = !this.dialogFormVisible;
    },
    //删除
    handleDelete(id) {
      this.$confirm('是否确认删除该访客？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$del("/oauth/user/delete", {userId: id}).then(() => {
          this.$message.success("删除成功！");
          this.getDataFromServer();
        }).catch(() => {
          this.$message.info("删除失败！");
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },// 提交
    commit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
         // 笼架编号
         //  let arr = this.formAction.cageList.map(o => o)
          this.formAction.cageIds = this.formAction.cageList.join(",");
          // 课题编号
          // let arr1 = this.formAction.subjectList.map(o => o)
          this.formAction.subIds = this.formAction.subjectList.join(",");
          // console.log(this.formAction.cageList.join(","))

          this.$delete(this.formAction, 'cageList')
          this.$delete(this.formAction, 'subjectList')
          let url, tit;
          if (!this.isEdit) {
            /*if (this.formAction.cageNoStr.length == 0 || this.formAction.subNumStr.length == 0) {
              this.$message({
                message: '请添加笼舍编号或者课题编号',
                type: 'warning'
              })
              return;
            }*/
            url = this.$post('/oauth/user/visitor', this.formAction)
            tit = '添加'
          } else {
            url = this.$put('/oauth/user/visitor', this.formAction)
            tit = '修改'
          }
          url.then(() => {
            this.dialogFormVisible = !this.dialogFormVisible;
            this.$message.success(tit + "成功")
            this.getDataFromServer();
          }).catch((err) => {
            this.$message.error(err.response.data.message)
          })

        }
      })
    },
    // 重置密码
    reset(row) {
      this.resetPwd = true
      this.newPwd.username = row.username
    },
    // 重置密码
    submit() {
      this.$put('/oauth/user/password', this.newPwd).then(() => {
        this.$message.success("重置成功")
        this.getDataFromServer();
        this.resetPwd = !this.resetPwd;
      }).catch((err) => {
        this.$message.error(err)
      })
    },
    // 更改访客权限
    changeVistor(row) {
      this.$confirm(`此操作将${row.status == '1' ? '关闭' : '开启'}操作权限, 是否继续？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let params = {
          status: row.status == '1' ? '0' : '1',
        }
        this.$put("/oauth/user/status/" + row.username, params).then(() => {
          this.$message.success("修改成功！");
          this.getDataFromServer();
        }).catch(() => {
          this.$message.info("修改失败！");
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消修改'
        })
      })
    },
  },

}
</script>

<style lang="scss" scoped>
@import "../../assets/css/exper/experiment";

.modeFor {
  margin-bottom: 10px;
}

.addInstru {
  width: 150px;
  height: 32px;
  border: 1px solid #BBBBBB;
  text-align: left;
  color: #999999;

  &:hover, &:focus {
    background-color: transparent;
    color: #999999;
  }
}

.delIns {
  border-color: #BBBBBB;
  padding: 8px 10px;
  margin-left: 10px;

  &:hover {
    border-color: #BBBBBB;
  }
}
</style>
